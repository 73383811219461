import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createTransform, FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer, { AuthState } from './slices/authSlice';
import projectsReducer from './slices/projectsSlice';
import usersReducer from './slices/usersSlice';
import tenantsReducer from './slices/tenantsSlice';
import groupsReducer from './slices/groupsSlice';
import rolesReducer from './slices/rolesSlice';
import permissionsReducer from './slices/permissionsSlice';
import profileReducer from './slices/profileSlice';
import languagesReducer from './slices/languagesSlice';
import activeItemReducer from './slices/activeItemSlice';
import sidebarReducer, { initialState as sidebarState } from './slices/sidebarOpenSlice';
import pageActionsReducer from './slices/pageActionsShowSlice';
import pagesReducer from './slices/pagesSlice';
import modulesReducer from './slices/moduleSlice';
import itemsReducer from './slices/itemSlice';
import menusReducer from './slices/menusSlice';
import settingsReducer from './slices/settingsSlice';
import pageStylesReduces from './slices/pageStylesSlice';
import targetGroupsReducer from './slices/targetGroupsSlice';
import audiencesReducer from './slices/audienceSlice';
import abTestingGroupsReducer from './slices/abTestingGroupSlice';
import appBrandingsReducer from './slices/appBrandingsSlice';
import displayConditionsReducer from './slices/displayConditionsSlice';
import dynamicSourcesReducer from './slices/sourceSlice';
import templatesReducer from './slices/templatesSlice';
import fileManagerReducer from './slices/fileManagerSlice';
import globalSearchReducer from './slices/searchSlice';
import copyObjectReducer from './slices/copyObjectSlice';
import deactivateObjectReducer from './slices/deactivateObjectSlice';
import unpublishedChangesReducer from './slices/unpublishedChangesSlice';
import previewItemsReducer from './slices/previewItemsSlice';
import analyticsReducer from './slices/analyticsSlice';
import applicationsReducer from './slices/applicationsSlice';
import filterDataReducer from './slices/filterDataSlice';
import objectActivityReducer from './slices/objectActivitySlice';
import configurableValuesReducer from './slices/configurableValuesSlice';
import ccSettingsReducer from './slices/ccSettingsSlice';
import configReducer from './slices/configSlice';
import featureFlagReducer from './slices/featureFlagSlice';

const LOGOUT = 'users/logUserOutStatus/fulfilled';
const SIDEBAR_KEY = 'sidebarOpen';
const AUTH_KEY = 'auth';

const transformCircular = createTransform(
    (inboundState, key) => {
        // we need to change the structure of persisted state of sidebarOpen before to initialize the Redux store in case there is still present the old structure.
        if (key === SIDEBAR_KEY && typeof inboundState === 'boolean') {
            const newState = { ...sidebarState, sidebarOpen: inboundState };
            return JSON.stringify(newState);
        }
        // Do not persist the loading state
        if (key === AUTH_KEY) {
            const { loading, ...rest } = inboundState as AuthState;
            return JSON.stringify(rest);
        }

        return JSON.stringify(inboundState);
    },
    (outboundState) => JSON.parse(outboundState)
);

const reducers = {
    auth: authReducer,
    config: configReducer,
    projects: projectsReducer,
    users: usersReducer,
    tenants: tenantsReducer,
    groups: groupsReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    languages: languagesReducer,
    pages: pagesReducer,
    modules: modulesReducer,
    items: itemsReducer,
    menus: menusReducer,
    settings: settingsReducer,
    pageStyles: pageStylesReduces,
    targetGroups: targetGroupsReducer,
    audiences: audiencesReducer,
    abTestingGroups: abTestingGroupsReducer,
    appBrandings: appBrandingsReducer,
    displayConditions: displayConditionsReducer,
    profile: profileReducer,
    activeItem: activeItemReducer,
    sidebarOpen: sidebarReducer,
    pageActionsShow: pageActionsReducer,
    dynamicSources: dynamicSourcesReducer,
    templates: templatesReducer,
    files: fileManagerReducer,
    search: globalSearchReducer,
    copyObject: copyObjectReducer,
    deactivateObject: deactivateObjectReducer,
    unpublishedChanges: unpublishedChangesReducer,
    preview: previewItemsReducer,
    analytics: analyticsReducer,
    applications: applicationsReducer,
    filterData: filterDataReducer,
    objectActivity: objectActivityReducer,
    configurableValues: configurableValuesReducer,
    ccSettings: ccSettingsReducer,
    featureFlags: featureFlagReducer
};

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    transforms: [transformCircular],
    whitelist: ['auth', 'activeItem', 'sidebarOpen']
};

const appReducer = combineReducers(reducers);

const rootReducer = (state: any, action: any) => {
    // in case the user logs out we reset the reducers back to the initial state
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
