import { TableBody, TableCell } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import icons from '../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import {
    createItem,
    deleteItem,
    deleteItemList,
    fetchContentSourceTypes,
    fetchItems,
    fetchItemTypes,
    ItemState,
    unsetItems,
    updateItem
} from '../../redux/slices/itemSlice';
import { setUserPermissions } from '../../redux/slices/permissionsSlice';
import {
    ApplicationWrapper,
    LastModifiedTableCell,
    MainContentWrapper,
    NoResourcesContainer,
    ObjectNameLabel,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../style/styled-components/reusable.css';
import { generateDateStringForTables } from '../../utils/fnDate';
import { DIALOG_NAMES, dialogConfirm, ToastAlert } from '../../utils/fnDialogs';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    calculateOrderByFromSortConfig,
    DEFAULT_SORT_CONFIG,
    ISortConfig
} from '../../utils/fnSort';
import { ResourceCard } from '../Cards/ResourceCard/ResourceCard';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import CreateResourceDialog from '../common/Dialog/CreateResourceDialog';
import Sidebar from '../common/Sidebar/Sidebar';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ImageTableCell,
    ScrollableTableRow,
    SelectAllTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../common/Table/Table';
import { WidthTableCell } from '../common/Table/Table.css';
import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../common/Tooltips/Tooltips';
import { RemoveModuleWrapper, TooltipDivider } from '../Modules/Modules.css';
import TemplateSelection from '../Pages/Dialogs/TemplateSelection';
import { ItemName } from './Items.css';
import SVGInline from 'react-inlinesvg';
import { Item, itemTypes } from '../../types/Item';
import { EditorialView } from './EditorialView';
import { EditorialTableRow } from './EditorialView.css';
import { NewItem } from './Dialogs/NewItem';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { Loader } from '../common/Loader/Loader';
import { fetchAudiences } from '../../redux/slices/audienceSlice';
import { fetchDisplayConditions } from '../../redux/slices/displayConditionsSlice';
import { API_ERROR_CODES, EMPTY_WORD_STRING } from '../../utils/Globals';
import { templateTypes } from '../../types/Template';
import { fetchPages, pagesState } from '../../redux/slices/pagesSlice';
import { resourceCardImages } from '../../assets/images/resourceCards';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import useScreenSize from '../../hooks/useScreenSize';
import { Hint, HINT_TYPES } from '../common/Hints/Hint';
import circleSlugs from '../../utils/circle_slugs.json';
import { renderPublishedDeleteError } from '../../utils/fnPublish';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { useMultiSelect } from '../../hooks/useMultiSelect';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderABIcon, renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../utils/fnLockingSystem';
import useBlockNavigation from '../../hooks/useBlockNavigation';
import { applyTemplate, templatesState } from '../../redux/slices/templatesSlice';
import { openDocumentation } from '../../utils/parsers';
import CopyToProjectDialog, { renderItemActionWarningAfterCopy, renderUnsavedWhenCopyingAlert } from '../common/Dialog/CopyToProjectDialog';
import { copyObject } from '../../redux/slices/copyObjectSlice';
import { ObjectTypes } from '../../types/Object';
import {
    ObjectNameTooltipContentHolder,
    ObjectNameTooltipIconHolder,
    ObjectNameTooltipLabelHolder,
    ObjectNameTooltipNameHolder
} from '../common/Tooltips/Tooltips.css';
import { getSearchParam } from '../../utils/fnUrl';
import avatarIcon from '../../assets/images/icons/ico-avatar.svg';
import { SourcesState } from '../../redux/slices/sourceSlice';
import { ItemsTableSizes, PagesTableSizes } from '../../types/TableSizes';
import { ApplicationsState } from '../../redux/slices/applicationsSlice';
import Labels from '../common/Labels/Labels';
import PaginationWrapper, { renderUnsavedAlertBeforeAction, ResetCallbackProps } from '../PaginationWrapper/PaginationWrapper';
import { renderFailedObjectDeletions } from '../../utils/fnListDelete';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { ObjectFilter } from '../../utils/fnFilter';
import { UserProfileState } from '../../redux/slices/profileSlice';
import { searchTermUnsetValue } from '../common/Select/FancyFilter';
import { renderAdminLockedError, renderAdminLockedWarningAlert, renderAdminLockIcon } from '../../utils/fnAdminLocking';
import { InputLabelWithIconWrapper } from '../common/Dialog/GenericDialog.css';
import { getObjectActivity, unsetObjectActivity } from '../../redux/slices/objectActivitySlice';
import ObjectActivityDialog from '../common/Dialog/ObjectActivityDialog';
import useTranslation from '../../hooks/useTranslation';
import { fetchAllLanguageTranslations, LanguagesState } from '../../redux/slices/languagesSlice';
import { renderDeactivatedIcon, renderFailedObjectStatusChange } from '../../utils/fnDeactivateSystem';
import {
    activateObject,
    activateObjectList,
    deactivateObject,
    deactivateObjectList,
    DeactivateObjectState
} from '../../redux/slices/deactivateObjectSlice';

export const Items: FC = () => {
    const [openTemplateSelectionDialog, setOpenTemplateSelectionDialog] = useState<boolean>(false);
    const [openNewItemDialog, setOpenNewItemDialog] = useState<boolean>(false);
    const [openCreateItemDialog, setOpenCreateItemDialog] = useState<boolean>(false);
    const [items, setItems] = useState<Item[]>([]);
    const [itemToEdit, setItemToEdit] = useState<Item | undefined>(undefined);
    const [duplicating, setDuplicating] = useState<boolean>(false);
    const [openNameTooltip, setOpenNameTooltip] = useState<any>(null);
    const [openEditViewItem, setOpenEditViewItem] = useState<Item | null>(null);
    const [showUnsaved, setShowUnsaved] = useState(false);
    // used to open visual editor on redirect
    const [previewItemId, setPreviewItemId] = useState<string>('');
    const [showHintScreen, setShowHintScreen] = useState<boolean>(false);
    const [showCopyToProjectDialog, setShowCopyToProjectDialog] = useState<boolean>(false);
    const [showObjectActivityDialog, setShowObjectActivityDialog] = useState<{
        open: boolean;
        objectTitle: string;
    }>({
        open: false,
        objectTitle: ''
    });

    const { error: sourcesError }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { error: applicationsError }: ApplicationsState = useAppSelector((state) => state.applications);
    const [editorialItemToSave, setEditorialItemToSave] = useState<Item | null>(null);
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { userProfile }: UserProfileState = useAppSelector((state) => state.profile);
    const { userPermissions } = useAppSelector((state) => state.permissions);
    const { error: copyObjectError } = useAppSelector((state) => state.copyObject);
    const { error: templatesError }: templatesState = useAppSelector((state) => state.templates);
    const { loading: objectActivityLoading } = useAppSelector((state) => state.objectActivity);
    const { error: pageError }: pagesState = useAppSelector((state) => state.pages);
    const { allTranslations }: LanguagesState = useAppSelector((state) => state.languages);
    const {
        items: storeItems,
        contentSourceTypes,
        storeItemTypes,
        totalResults,
        loading,
        error
    }: ItemState = useAppSelector((state) => state.items);
    const { error: deactivateError }: DeactivateObjectState = useAppSelector((state) => state.deactivateObject);
    const { translate } = useTranslation();

    const canCopy = userPermissions && (userPermissions.isSuperAdmin || userPermissions.tenantAdminIn?.includes(activeTenantId || ''));

    // MULTISELECT ITEMS
    const [isMultiSelectVisible, setIsMultiSelectVisible] = useState<boolean>(false);
    const [multiSelectedIds, setMultiSelectedIds] = useState<string[]>([]);
    const [allItemsSelected, setAllItemsSelected] = useState<boolean>(false);

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.lastModified);
    const [activeObjectFilter, setActiveObjectFilter] = useState<ObjectFilter | undefined>(undefined);

    const orderBy = calculateOrderByFromSortConfig(sortConfig);

    const resetCallback = (keep?: ResetCallbackProps) => {
        !keep?.currentPage && setCurrentPage(1);
        !keep?.searchTerm && setSearchTerm(undefined);
        !keep?.sortConfig && setActiveSortingKey(DEFAULT_SORT_CONFIG.field as AcceptedSortField);
        !keep?.sortConfig && setSortConfig(DEFAULT_SORT_CONFIG);
        !keep?.filterObject && setActiveObjectFilter(undefined);
        setIsMultiSelectVisible(false);
        if (!keep?.visualEditor) {
            openEditViewItem?._id && unlock(openEditViewItem?._id);
            setOpenEditViewItem(null);
            setEditorialItemToSave(null);
        }
    };

    const { isMobile, isDesktop, isLargeDesktop } = useScreenSize();
    const { select } = useMultiSelect();

    // Locking
    const { lock, unlock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.ITEMS);
    const lockItem = (id: string) => {
        lock(id);
        unlockOnClose(id);
    };

    useBlockNavigation(!!editorialItemToSave, () => renderEditingAlertDialog(), [editorialItemToSave]);

    // This method is used to load content source types, in case they are not already loaded in the slice
    const loadResources = async () => {
        loadContentSourceTypes();
        if (!storeItemTypes?.length) loadItemTypes();
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();

    const loadItems = async (
        addPermissions: boolean,
        projectId: string = '',
        pageSize: number,
        pageNumber: number,
        orderBy: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        // if redirected, reset the search term and filter after any action
        const isRedirected = !!activeObjectFilter?._id;
        if (isRedirected) {
            setActiveObjectFilter(undefined);
            setSearchTerm(undefined);
        } else {
            // there might be a searchTerm in the fancy filter but a search was not conducted, so we need to clear the searchTerm inside the fancy filter
            // to do that we assign the searchTerm a special value which will be used in the fancy filter to clear both search terms
            const itemId = location?.state?.itemId || getSearchParam('id');
            if (!itemId && !searchTerm) setSearchTerm(searchTermUnsetValue);
        }

        return await dispatch(
            fetchItems({
                addPermissions,
                projectId,
                pageSize,
                pageNumber,
                orderBy,
                ...(!isRedirected && { searchTerm }),
                ...(!isRedirected && { filter })
            })
        ).unwrap();
    };

    const onSearchTermOrPaginationChange = (
        pageSize: number,
        currentPage: number,
        orderBy: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        setOpenEditViewItem(null);
        setIsMultiSelectVisible(false);
        loadItems(true, activeProjectId || '', pageSize, currentPage, orderBy, searchTerm, filter);
    };

    const handleSortIconClick = (field: AcceptedSortField, specifiedDirection?: 'asc' | 'desc', currentPage?: number) => {
        if (showUnsaved) return renderUnsavedAlertBeforeAction();
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = specifiedDirection || 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc' && !specifiedDirection) {
            direction = 'desc';
        }

        const config = {
            direction,
            field
        };

        const orderBy = `${config.field}[${config.direction}]`;

        setSortConfig(config);
        !currentPage && setCurrentPage(1);
        loadItems(false, activeProjectId || '', pageSize, currentPage || 1, orderBy, searchTerm, activeObjectFilter);
        // make sure sort arrows don't remain open after reloading
        setShowSortArrows(false);
        setOpenEditViewItem(null);
        setIsMultiSelectVisible(false);
    };

    const loadContentSourceTypes = async () => {
        return await dispatch(fetchContentSourceTypes({ projectId: activeProjectId }));
    };

    const loadItemTypes = async () => {
        return await dispatch(fetchItemTypes());
    };

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId }));
    };

    const loadConditions = async () => {
        return await dispatch(fetchDisplayConditions({ addPermissions: false, projectId: activeProjectId }));
    };

    const loadPages = async (projectId?: string) => {
        return await dispatch(fetchPages({ addPermissions: false, projectId })).unwrap();
    };

    const loadAllTranslations = async () => {
        return await dispatch(fetchAllLanguageTranslations({ projectId: activeProjectId || '' })).unwrap();
    };

    const removeItem = async (id: string) => {
        const response = await dispatch(deleteItem(id)).unwrap();
        if (response.ok) {
            loadItems(false, activeProjectId || '', pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        }
    };

    const removeList = async (items: string[]) => {
        const result = await dispatch(deleteItemList(items)).unwrap();
        if (result.failedDeletions.length) {
            renderFailedObjectDeletions(result.failedDeletions);
        }
        resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        loadItems(false, activeProjectId || '', pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
    };

    const saveItem = async (item: Item) => {
        try {
            const result = await dispatch(createItem(item)).unwrap();
            if (!result.id) return false;

            // when creating an item, close the visualEditor and unlock module
            if (openEditViewItem) {
                unlock(openEditViewItem._id);
                setOpenEditViewItem(null);
            }
            resetCallback();
            loadItems(false, activeProjectId || '', pageSize, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG));
        } catch (ex) {
            return false;
        }
        return true;
    };

    const modifyItem = async (item: Item, shouldUnlockAfterSave?: boolean) => {
        try {
            const result = await dispatch(updateItem({ item, shouldUnlockAfterSave: shouldUnlockAfterSave })).unwrap();
            if (!result.ok) return false;

            resetCallback({ visualEditor: true, filterObject: true, searchTerm: true });
            loadItems(
                false,
                activeProjectId || '',
                pageSize,
                1,
                calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG),
                searchTerm,
                activeObjectFilter
            );
        } catch (ex) {
            return false;
        }
        return true;
    };

    const updateEditorialItem = () => {
        if (!editorialItemToSave) return;
        modifyItem(editorialItemToSave, false);
    };

    const toggleToolTip = (tooltip: any, index?: number) => {
        const newTooltip = { ...tooltip };
        Object.keys(newTooltip).forEach((key) => {
            newTooltip[key] = index !== undefined ? key === index.toString() : false;
        });
        return newTooltip;
    };

    const useTemplate = async (id: string) => {
        const result = await dispatch(
            applyTemplate({ templateId: id, type: templateTypes.ITEM, projectId: `${activeProjectId}`, tenantId: `${activeTenantId}` })
        ).unwrap();
        if (result.id) {
            resetCallback();
            loadItems(false, activeProjectId || '', pageSize, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG));
        }
    };

    const renderEditingAlertDialog = (closeCallback?: () => void) => {
        return dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                // update then close item
                updateEditorialItem();
                // closing
                setOpenEditViewItem(null);
                setEditorialItemToSave(null);
                setShowUnsaved(false);
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                // close item
                setOpenEditViewItem(null);
                setEditorialItemToSave(null);
                setShowUnsaved(false);
                closeCallback?.();
            },
            true
        );
    };

    const toggleEditingViewMode = (item?: Item) => {
        if (editorialItemToSave) {
            renderEditingAlertDialog(() => {
                setOpenEditViewItem(item ? item : null);
            });
        } else {
            setOpenEditViewItem(item ? item : null);
        }
    };

    useEffect(() => {
        !isDesktop && setOpenEditViewItem(null);
    }, [isDesktop]);

    useEffect(() => {
        setIsMultiSelectVisible(false);
        setMultiSelectedIds([]);
    }, [currentPage]);

    useEffect(() => {
        activeSortingKey && setShowSortArrows(false);
    }, [activeSortingKey, sortConfig]);

    useEffect(() => {
        const newTooltips = { ...openNameTooltip };
        items.forEach((_, index) => {
            newTooltips[index] = false;
        });
        setOpenNameTooltip(newTooltips);
    }, [items]);

    useEffect(() => {
        setTotalPages(totalResults !== undefined ? Math.ceil(totalResults / pageSize) : 1);
    }, [pageSize, totalResults]);

    useEffect(() => {
        if (loading || error) return;

        setItems(storeItems);
        setEditorialItemToSave(null);
    }, [loading]);

    useEffect(() => {
        if (!activeProjectId) return;
        // everything should be reset between project changes
        resetCallback();
        setPageSize(10);
        if (storeItems.length) {
            dispatch(unsetItems());
        }

        const redirectItemId = getSearchParam('id');
        const itemId = location?.state?.itemId || redirectItemId;
        let filter: ObjectFilter = {};
        if (itemId) {
            filter._id = [itemId];
            setActiveObjectFilter(filter);
        }
        // when redirected we should load translation to display the translated value as a searchTerm
        if (itemId && !allTranslations) {
            loadAllTranslations();
        }

        loadItems(true, activeProjectId, 10, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), undefined, filter)
            .then((response: any) => {
                if (response.permissions) {
                    setUserPermissions(response.permissions);
                }
            })
            .then(() => {
                loadAudiences();
                loadResources();
                loadConditions();
                loadPages(activeProjectId);
            });

        return () => {
            setOpenEditViewItem(null);
        };
    }, [activeProjectId]);

    // When redirected open Edit, Create or Visual Editor
    useEffect(() => {
        if (loading || error || !storeItems.length || itemToEdit) return;

        // Used when opening the editor in a new tab
        const redirectItemId = getSearchParam('id');
        const itemId = location?.state?.itemId || redirectItemId;
        const openCreateItem = location?.state?.openCreate;
        const openVisualEditor = location?.state?.openVisualEditor || redirectItemId;

        if (storeItems.length && getSearchParam('id')) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.ITEMS), { replace: true });
        }

        if (openCreateItem) {
            setOpenCreateItemDialog(true);
        }

        if (itemId) {
            const item = storeItems.find((ci) => ci._id === itemId);
            if (item && userProfile) {
                //clear the location state only after it is sucessfully found and opened;
                navigate(location.pathname, { state: { itemId: undefined, openVisualEditor: undefined }, replace: true });
                const locked = isObjectLocked(item);
                const lockedBy = objectIsLockedBy(item);
                if (locked && lockedBy) {
                    renderLockedWarningAlert(lockedBy);
                } else {
                    lockItem(item._id);
                }
                if (item.adminLocked) {
                    renderAdminLockedWarningAlert(item.name);
                }
                if (openVisualEditor) {
                    setPreviewItemId(itemId);
                    setOpenEditViewItem(item);
                } else {
                    editItem(item);
                }
                setSearchTerm(translate(item.name) || `${EMPTY_WORD_STRING} ID: ${itemId.substring(0, 8)}...`);
            }
        }
    }, [storeItems, userProfile, loading]);

    useEffect(() => {
        if (!items?.length) return;
        setAllItemsSelected(multiSelectedIds.length === items.length);
    }, [multiSelectedIds, items]);

    useEffect(() => {
        if (!isMultiSelectVisible) {
            setAllItemsSelected(false);
            setMultiSelectedIds([]);
        }
    }, [isMultiSelectVisible]);

    const handleDeleteIconClick = (id?: string, isEditorial?: boolean) => {
        const values = {
            title: `Remove ${!id ? 'Multiple Lists & Items' : isEditorial ? 'Editorial Item' : 'Dynamic List'}`,
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                id ? removeItem(id) : removeList(multiSelectedIds);
                setPreviewItemId('');
            },
            values,
            <RemoveModuleWrapper>
                <SVGInline src={icons.warningIcon} />
                <p>
                    <strong>{`Are you sure you want to remove  ${id ? 'this Item' : 'the selected Lists & Items'}?`}</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove',
                    type: !id ? 'RED' : undefined
                }
            },
            undefined,
            undefined,
            true
        );
    };

    const editItem = (item: Item) => {
        setItemToEdit(item);
        setOpenNewItemDialog(true);
    };

    const duplicateItem = (item: Item) => {
        setItemToEdit(item);
        setOpenNewItemDialog(true);
        setDuplicating(true);
    };

    const deactivateItem = async (id: string) => {
        const result = await dispatch(deactivateObject({ objectId: id, objectType: LockableObjectTypes.ITEMS })).unwrap();
        if (result.ok) {
            loadItems(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        }
    };
    const activateItem = async (id: string) => {
        const result = await dispatch(activateObject({ objectId: id, objectType: LockableObjectTypes.ITEMS })).unwrap();
        if (result.ok) {
            loadItems(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        }
    };

    const deactivateItemsList = async (itemIds: string[]) => {
        const result = await dispatch(deactivateObjectList({ objectIds: itemIds, objectType: LockableObjectTypes.ITEMS })).unwrap();

        if (result.failedActions.length) {
            renderFailedObjectStatusChange(result.failedActions);
        }
        if (result.ok) {
            loadItems(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        }
    };

    const activateItemsList = async (itemIds: string[]) => {
        const result = await dispatch(activateObjectList({ objectIds: itemIds, objectType: LockableObjectTypes.ITEMS })).unwrap();
        if (result.failedActions.length) {
            renderFailedObjectStatusChange(result.failedActions);
        }
        if (result.ok) {
            loadItems(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        }
    };

    const handleCopyToProject = async (
        projectId: string,
        showItemActionWarningAfterCopy?: boolean,
        withRedirect?: boolean,
        hideSuccessToast?: boolean
    ) => {
        if (!itemToEdit) return;

        const result = await dispatch(copyObject({ _id: itemToEdit._id, projectId, objectType: 'items' })).unwrap();

        if (result.id) {
            showItemActionWarningAfterCopy && renderItemActionWarningAfterCopy();
            !hideSuccessToast && ToastAlert('success', '', '', undefined, DIALOG_NAMES.OBJECT_COPY);
            withRedirect &&
                navigate(buildPathWithProjectId(projectId, PageRoutes.ITEMS), {
                    state: {
                        itemId: result.id,
                        openVisualEditor: true
                    }
                });
        }
        setShowCopyToProjectDialog(false);
    };

    const handleNewItemDialogClose = () => {
        // if EDITING the and visual editor is NOT open, unlock the item, otherwise keep it locked
        if (itemToEdit && !openEditViewItem) {
            unlock(itemToEdit._id);
        }
        setOpenNewItemDialog(false);
        setItemToEdit(undefined);
        setDuplicating(false);
    };

    const renderVisualEditor = (item: Item) => {
        const { itemType, _id } = item;
        if (!openEditViewItem || openEditViewItem._id !== _id) return;

        const locked = isObjectLocked(item);
        const lockedBy = objectIsLockedBy(item);
        return (
            <EditorialTableRow>
                <TableCell colSpan={9}>
                    <EditorialView
                        type={itemType}
                        item={item}
                        onDuplicate={() => duplicateItem(item)}
                        onEdit={() => {
                            editItem(item);
                            if (item.adminLocked) {
                                return renderAdminLockedWarningAlert(item.name);
                            }
                            if (locked) {
                                return renderLockedWarningAlert(lockedBy);
                            }
                        }}
                        onRemove={() => {
                            if (item.adminLocked) {
                                return renderAdminLockedWarningAlert(item.name);
                            }
                            if (locked) {
                                return renderLockedWarningAlert(lockedBy);
                            }
                            handleDeleteIconClick(_id);
                        }}
                        onSave={() => updateEditorialItem()}
                        onCancel={() => {
                            unlock(item._id);
                            toggleEditingViewMode();
                            setPreviewItemId('');
                        }}
                        setItemToSave={(arg) => {
                            setShowUnsaved(!!arg);
                            setEditorialItemToSave(arg);
                        }}
                    />
                </TableCell>
            </EditorialTableRow>
        );
    };

    const renderNoItems = () => (
        <NoResourcesContainer>
            <ResourceCard
                image={resourceCardImages.itemsNewCard}
                title={'Create List or Item'}
                subtitle={'Create List or Item from scratch'}
                primaryButtonLabel={'Create List or Item'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    setOpenNewItemDialog(true);
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.items);
                }}
                createResource
            />
            <ResourceCard
                image={resourceCardImages.itemsCard}
                title={'Select Template'}
                subtitle={'Add a list or item selecting existing templates'}
                primaryButtonLabel={'Select Template'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    setOpenTemplateSelectionDialog(true);
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.templates);
                }}
            />
        </NoResourcesContainer>
    );

    const renderNameTooltip = (name: string, index: number, item: Item, locked: boolean, lockedBy: string) => {
        const labelName = item.itemType === itemTypes.EDITORIAL ? 'Editorial Item' : 'Dynamic List';
        const tooltipValue = (
            <ObjectNameTooltipContentHolder>
                <ObjectNameTooltipIconHolder>
                    <SVGInline src={icons.itemsIcon} />
                </ObjectNameTooltipIconHolder>
                <ObjectNameTooltipNameHolder>{translate(name || EMPTY_WORD_STRING)}</ObjectNameTooltipNameHolder>
                <TooltipDivider />
                <ObjectNameTooltipLabelHolder
                    onClick={(e: React.MouseEvent<any>) => {
                        e.stopPropagation();
                        //if the same item is already opened in editorial view the function shoudn't be called
                        if (openEditViewItem && openEditViewItem._id === item._id) return;
                        toggleEditingViewMode(item);
                    }}
                >
                    {`Edit ${labelName}`}
                </ObjectNameTooltipLabelHolder>
            </ObjectNameTooltipContentHolder>
        );
        return renderTooltip(
            <ItemName>
                <TruncatedText
                    onMouseEnter={() =>
                        setOpenNameTooltip((tooltips: any) => {
                            return toggleToolTip(tooltips, index);
                        })
                    }
                >
                    {translate(name || EMPTY_WORD_STRING)}
                </TruncatedText>
                <TranslationTooltip translationKey={item.name} />
                {locked && renderLockIcon(lockedBy)}
                {item.adminLocked && renderAdminLockIcon()}
                {item.deactivated && renderDeactivatedIcon()}
                {renderABIcon(item)}
            </ItemName>,
            tooltipTypes.HTML,
            tooltipValue,
            tooltipPositions.TOP,
            false,
            true,
            false,
            openNameTooltip?.[index] || false
        );
    };

    const buildTableColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'List Or Item Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={ItemsTableSizes['name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <SortableHeaderTableCell
                key={`content_source_cell`}
                text={'Content Source'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.contentSourceType}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.contentSourceType)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={ItemsTableSizes['source']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.contentSourceType && sortConfig?.direction) || 'asc'}
            />,
            <SortableHeaderTableCell
                key={`max_num_of_items_cell`}
                text={'No'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.maxNumberOfItems}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.maxNumberOfItems)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={ItemsTableSizes['maxNumberOfItems']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.maxNumberOfItems && sortConfig?.direction) || 'asc'}
            />,
            <HeaderTableCell key={'placed_cell'} text={'Part of Module(s)'} columnSize={ItemsTableSizes.placed} />,
            <HeaderTableCell key={'conditions_cell'} text={'Target Conditions'} columnSize={ItemsTableSizes.conditions} />,
            <SortableHeaderTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={PagesTableSizes['lastModified']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];

        items?.length &&
            columns.push(
                <>
                    {isLargeDesktop && <TableCell key={`empty_cell_items`} />}
                    <SelectAllTableCell
                        onDelete={handleDeleteIconClick}
                        onSelectAll={(selected) => {
                            setMultiSelectedIds(selected ? items.map((item) => item._id) : []);
                        }}
                        onDeactivate={() => {
                            deactivateItemsList(multiSelectedIds);
                        }}
                        onActivate={() => {
                            activateItemsList(multiSelectedIds);
                        }}
                        allValuesSelected={allItemsSelected}
                        isMultiSelectVisible={isMultiSelectVisible}
                        setIsMultiSelectVisible={(arg) => setIsMultiSelectVisible(arg)}
                        isActionsDisabled={!multiSelectedIds.length}
                    />
                </>
            );
        !isDesktop && columns.splice(5, 1); // remove last modified column if not in desktop view
        return columns;
    };

    const buildTableBody = () => {
        const rows: JSX.Element[] = items.map((item, index) => {
            const lastModified = generateDateStringForTables(item?.lastModified || 0);
            const itemType = storeItemTypes?.find((type) => type.value === item.itemType);
            const contentSource =
                itemType?.value === itemTypes.DYNAMIC &&
                contentSourceTypes?.find(
                    (source) => source.value === item.dynamicSource?.service || source.value === item.contentSourceType
                );
            const locked = isObjectLocked(item);
            const lockedBy = objectIsLockedBy(item);

            const targetConditionsInfo =
                !item || !item?.placed?.length
                    ? `The ${
                          itemType?.value === itemTypes.DYNAMIC ? 'Dynamic List' : 'Editorial Item'
                      } will automatically inherit the Module’s Audiences when added to it.`
                    : `The ${itemType?.value === itemTypes.DYNAMIC ? 'Dynamic List' : 'Editorial Item'} has one or multiple parents.
                            Navigate to the parent objects to check the exact targeting conditions.`;

            return (
                <>
                    <ScrollableTableRow
                        shouldScroll={previewItemId === item._id}
                        key={index}
                        style={{ cursor: 'pointer' }}
                        data-cy={`item-row-${index}`}
                        onClick={() => {
                            //if the same item is already opened in editorial view the function shoudn't be called
                            if (openEditViewItem && openEditViewItem._id === item._id) return;
                            if (!isDesktop) {
                                return setShowHintScreen(true);
                            }
                            if (item.adminLocked) {
                                renderAdminLockedWarningAlert(item.name);
                            }
                            // If it's locked by someone show alert and prevent opening
                            if (locked && lockedBy) {
                                renderLockedWarningAlert(lockedBy);
                            }
                            //show visual editor and lock item
                            toggleEditingViewMode(item);
                            lockItem(item._id);
                            setPreviewItemId('');
                        }}
                    >
                        {/* ITEM NAME TABLE CELL */}
                        <WidthTableCell
                            {...ItemsTableSizes.name}
                            onMouseLeave={() => {
                                setOpenNameTooltip((tooltip: any) => {
                                    return toggleToolTip(tooltip);
                                });
                            }}
                        >
                            {renderNameTooltip(item.name || EMPTY_WORD_STRING, index, item, locked, lockedBy)}
                        </WidthTableCell>

                        {/* CONTENT SOURCE TABLE CELL */}
                        <WidthTableCell {...ItemsTableSizes.source}>
                            <TruncatedText>{contentSource ? contentSource?.title : itemType?.title}</TruncatedText>
                        </WidthTableCell>

                        {/* NO TABLE CELL */}
                        <WidthTableCell {...ItemsTableSizes.maxNumberOfItems}>
                            {itemType?.value === itemTypes.EDITORIAL ? '1' : item.maxNumberOfItems}
                        </WidthTableCell>

                        {/* PLACED TABLE CELL */}
                        <WidthTableCell {...ItemsTableSizes.placed}>
                            <Labels
                                withTranslationTooltip
                                values={item.placed || []}
                                type={MoreInfoTypes.PLACED}
                                noOfLabels={isLargeDesktop ? 3 : isMobile ? 0 : 1}
                                onClickLabel={(obj) => {
                                    navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MODULES), {
                                        state: {
                                            moduleId: obj._id,
                                            redirected: true
                                        }
                                    });
                                }}
                            />
                        </WidthTableCell>

                        {/* TARGET CONDITIONS TABLE CELL */}
                        <WidthTableCell {...ItemsTableSizes.conditions}>
                            {!item.conditions?.length ? (
                                <ObjectNameLabel>
                                    <InputLabelWithIconWrapper>
                                        {!isMobile && 'Parent Conditions'}
                                        {renderTooltip(
                                            <SVGInline src={icons.infoIcon} />,
                                            tooltipTypes.TEXT,
                                            targetConditionsInfo,
                                            tooltipPositions.TOP
                                        )}
                                    </InputLabelWithIconWrapper>
                                </ObjectNameLabel>
                            ) : (
                                <Labels
                                    values={item.conditions}
                                    type={MoreInfoTypes.TARGETS}
                                    noOfLabels={isLargeDesktop ? 2 : isMobile ? 0 : 1}
                                    onClickLabel={(obj) => {
                                        const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                        navigate(
                                            buildPathWithProjectId(
                                                activeProjectId,
                                                isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                            ),
                                            {
                                                state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                            }
                                        );
                                    }}
                                />
                            )}
                        </WidthTableCell>

                        {/* LAST MODIFIED TABLE CELL */}
                        {isDesktop && (
                            <WidthTableCell {...ItemsTableSizes.lastModified}>
                                <LastModifiedTableCell
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        setShowObjectActivityDialog({ open: true, objectTitle: translate(item.name) });
                                        !objectActivityLoading &&
                                            dispatch(
                                                getObjectActivity({
                                                    objectId: item._id,
                                                    objectType: ObjectTypes.ITEMS,
                                                    withObjectName: true
                                                })
                                            );
                                    }}
                                >
                                    <TruncatedText>{lastModified}</TruncatedText>
                                    {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'generic_icon_activity_log')}
                                </LastModifiedTableCell>
                            </WidthTableCell>
                        )}

                        {/* MODIFIED BY TABLE CELL */}
                        {isLargeDesktop && (
                            <ImageTableCell
                                um={'px'}
                                shape="round"
                                src={item?.modifiedByUser?.icon || avatarIcon}
                                toolTipName={item?.modifiedByUser?.name}
                                imageSize={{ width: 32, height: 32 }}
                            />
                        )}

                        {/* ACTIONS TABLE CELL */}
                        <WidthTableCell {...ItemsTableSizes.actions}>
                            <ActionsTableCell
                                actions={
                                    isMultiSelectVisible
                                        ? [tableActions.MULTI_SELECT]
                                        : [
                                              tableActions.DEACTIVATE,
                                              tableActions.COPY,
                                              tableActions.EDIT,
                                              tableActions.DUPLICATE,
                                              tableActions.REMOVE
                                          ]
                                }
                                canCopy={item.itemType === itemTypes.EDITORIAL && canCopy}
                                onCopy={(e: React.MouseEvent<any>) => {
                                    e.stopPropagation();
                                    if (item.adminLocked) {
                                        return renderAdminLockedWarningAlert(item.name);
                                    }
                                    if (locked) {
                                        return renderLockedWarningAlert(lockedBy);
                                    }
                                    if (editorialItemToSave) {
                                        return renderUnsavedWhenCopyingAlert();
                                    }
                                    setItemToEdit(item);
                                    setShowCopyToProjectDialog(true);
                                }}
                                onEdit={(e: React.MouseEvent<any>) => {
                                    if (item.adminLocked) {
                                        renderAdminLockedWarningAlert(item.name);
                                    }
                                    if (locked) {
                                        renderLockedWarningAlert(lockedBy);
                                    }
                                    editItem(item);
                                    lockItem(item._id);
                                    e.stopPropagation();
                                }}
                                onDuplicate={(e: React.MouseEvent<any>) => {
                                    duplicateItem(item);
                                    e.stopPropagation();
                                }}
                                onRemove={(e: React.MouseEvent<any>) => {
                                    e.stopPropagation();
                                    if (item.adminLocked) {
                                        return renderAdminLockedWarningAlert(item.name);
                                    }
                                    if (locked) {
                                        return renderLockedWarningAlert(lockedBy);
                                    }
                                    handleDeleteIconClick(item._id, item.itemType === itemTypes.EDITORIAL);
                                }}
                                onDeactivate={(e: React.MouseEvent<any>) => {
                                    e.stopPropagation();
                                    if (item.adminLocked) {
                                        return renderAdminLockedWarningAlert(item.name);
                                    }
                                    if (locked) {
                                        return renderLockedWarningAlert(lockedBy);
                                    }
                                    if (showUnsaved) {
                                        return renderUnsavedAlertBeforeAction();
                                    }
                                    if (item.deactivated) {
                                        activateItem(item._id);
                                    } else {
                                        deactivateItem(item._id);
                                    }
                                }}
                                tooltipTexts={{
                                    copy: 'items_icon_copy',
                                    edit: 'items_icon_edit',
                                    duplicate: 'items_icon_duplicate',
                                    delete: 'items_icon_delete'
                                }}
                                publishedStatus={item.publishStatus}
                                onMultiSelect={() => {
                                    const itemsIds = items.map((item) => item._id);
                                    const newValues = select(itemsIds, multiSelectedIds, item._id);
                                    setMultiSelectedIds([...newValues]);
                                }}
                                selected={multiSelectedIds.includes(item._id)}
                                deactivated={item.deactivated}
                            />
                        </WidthTableCell>
                    </ScrollableTableRow>
                    {renderVisualEditor(item)}
                </>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };

    const isEmpty = !storeItems.length;

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.PUBLISHED_REFERENCE_OBJECT:
                return renderPublishedDeleteError(error);
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            case API_ERROR_CODES.ADMIN_LOCKED_ERROR:
                return renderAdminLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    const generalError = error || copyObjectError || templatesError || sourcesError || pageError || applicationsError || deactivateError;

    return (
        <>
            {generalError && renderError(generalError)}

            <ApplicationWrapper>
                {!openEditViewItem && <Sidebar onProjectSelect={(projectId: string) => projectId} />}
                <MainContentWrapper noSidebar={!!openEditViewItem} noTransition>
                    <ScreenTitle
                        loading={loading}
                        title="Lists & Items"
                        withAddButton={!isEmpty}
                        withProfile
                        backIcon={openEditViewItem !== null}
                        addLabel={'Create List or Item'}
                        onAdd={() => {
                            setOpenCreateItemDialog(true);
                        }}
                        onBack={() => {
                            if (openEditViewItem) {
                                unlock(openEditViewItem._id);
                            }
                            if (editorialItemToSave) {
                                renderEditingAlertDialog();
                            } else {
                                setOpenEditViewItem(null);
                            }
                        }}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.items, onboarding: ONBOARDING_CIRCLE_SLUGS.items }}
                    />

                    <PaginationWrapper
                        orderBy={orderBy}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        activeObjectFilter={activeObjectFilter}
                        onSearchTermOrPaginationChange={onSearchTermOrPaginationChange}
                        setActiveObjectFilter={(value: ObjectFilter) => setActiveObjectFilter(value)}
                        extraPadding={!!openEditViewItem}
                        resetCallback={resetCallback}
                        showUnsaved={showUnsaved}
                        type={ObjectTypes.ITEMS}
                    >
                        {loading ? (
                            <Loader title={'Lists & Items'} />
                        ) : isEmpty ? (
                            renderNoItems()
                        ) : (
                            <>
                                <GenericTable body={buildTableBody()} columns={buildTableColumns()} dataCy={'items-table'} />

                                <PageActionsWrapper>
                                    <PageActionButton
                                        onClick={() => setOpenNewItemDialog(true)}
                                        label={'Create list or item'}
                                        type={'BLUE'}
                                    />
                                    <PageActionButton
                                        onClick={() => setOpenTemplateSelectionDialog(true)}
                                        label={'Select Template'}
                                        type={'BLUE'}
                                    />
                                </PageActionsWrapper>
                            </>
                        )}
                    </PaginationWrapper>
                </MainContentWrapper>
                <TemplateSelection
                    open={openTemplateSelectionDialog}
                    onClose={() => {
                        setOpenTemplateSelectionDialog(false);
                    }}
                    templateUrl={''}
                    callback={(id) => {
                        useTemplate(id || '');
                        setOpenTemplateSelectionDialog(false);
                        setOpenCreateItemDialog(false);
                    }}
                    resourceType={templateTypes.ITEM}
                />
                {!isEmpty && !loading && (
                    <CreateResourceDialog
                        title={'Lists & Items'}
                        open={openCreateItemDialog}
                        handleOpenTemplateClick={() => {
                            setOpenTemplateSelectionDialog(true);
                        }}
                        onClose={() => {
                            setOpenCreateItemDialog(false);
                        }}
                        historyUrl={''}
                        handleCreateNewResourceClick={() => {
                            setOpenNewItemDialog(true);
                        }}
                    />
                )}
                {!loading && openNewItemDialog && (
                    <NewItem
                        open={openNewItemDialog}
                        onClose={handleNewItemDialogClose}
                        onSave={async (newItem) => {
                            let saveResult = false;
                            if (itemToEdit && !duplicating) {
                                saveResult = await modifyItem(newItem);
                            } else {
                                saveResult = await saveItem(newItem);
                                if (saveResult) {
                                    setOpenCreateItemDialog(false);
                                    setOpenTemplateSelectionDialog(false);
                                }
                            }
                            if (!saveResult) return;
                            setPreviewItemId('');
                            if (duplicating && itemToEdit) {
                                unlock(itemToEdit._id);
                            }
                            handleNewItemDialogClose();
                        }}
                        item={itemToEdit}
                        duplicate={duplicating}
                    />
                )}
                {showCopyToProjectDialog && (
                    <CopyToProjectDialog
                        open={showCopyToProjectDialog}
                        onClose={() => {
                            setShowCopyToProjectDialog(false);
                            setItemToEdit(undefined);
                        }}
                        objectId={itemToEdit?._id || ''}
                        objectType={ObjectTypes.ITEMS}
                        onCopy={(projectId: any, _, showItemActionWarningAfterCopy, withRedirect, hideSuccessToast) => {
                            handleCopyToProject(projectId, showItemActionWarningAfterCopy, withRedirect, hideSuccessToast);
                        }}
                    />
                )}
                <Hint type={HINT_TYPES.SCREEN_SIZE_CIS} showHint={showHintScreen} onHide={() => setShowHintScreen(false)} />
                <ObjectActivityDialog
                    objectType={ObjectTypes.ITEMS}
                    open={showObjectActivityDialog.open}
                    objectTitle={showObjectActivityDialog.objectTitle}
                    onClose={() => {
                        dispatch(unsetObjectActivity());
                        setShowObjectActivityDialog({
                            open: false,
                            objectTitle: ''
                        });
                    }}
                />
            </ApplicationWrapper>
        </>
    );
};
